.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }

  h1 {
    color: #007bff;
  }

  h2 {
    color: #333;
    margin-top: 20px;
  }

  p {
    margin-bottom: 15px;
  }



  .contact-link {
    text-decoration: none;
    font-weight: bold;
  }

  .contact-link:hover {
    text-decoration: underline;
  }
