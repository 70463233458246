*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.h3_recipt{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
}