.outer-container {
  width: 812px;
  padding: 30px;
}

.no-margin-padding {
  margin: 0;
  padding: 2px;
  font-size: 12px;
}
.italic {
  font-style: italic;
  font-size: 8px;
}

.Btn_download{
  height: 50px;
  width: 30%;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0);
  font-size: large;
  font-weight: 500;
  background-color: rgb(230, 253, 196) ;
  box-shadow: 1px 1px 10px rgba(226, 95, 95, 0.5);
  margin: 20px;
  transition: background-color 0.3s ease-out;
}

.Btn_download:hover{
  background-color: #BCD700 ;
  box-shadow: 5px 5px 10px rgba(226, 95, 95, 0.5);

}

.division-container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.company-logo {
  width: 50%;
  padding: 20px;
}

.table-outer-container {
  /* border: 1px solid blue; */
  width: 45%;
  margin-top: 20px;
}
p {
  margin: 0;
  padding: 2px;
}

.table-container {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.table-col-type-one {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  background-image: linear-gradient(to bottom, white, rgb(197, 197, 197));

}


.table-col-type-two {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.table-col-type-three {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

}

.table-col-sub{
  font-size: 10px;
  padding-left: 8px;
}

.division-container-one{
    display:flex;
    margin-top : 30px;
}

.text-container-one{
    align-self: flex-start;
    flex : 3;
}

.text-container{
    flex : 2;
}

.bold{
    font-weight: bold;
}

.bold-1{
  font-weight: 500;
}

p{
    font-weight: 500;
}

.empty-div{
  margin-top: 50px;
  height: 45px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to right, white, rgb(202, 202, 202));
}

.table-container-one{
  margin-top: 50px;
}

table{
  width: 100%;
}

table{
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
  padding: 5px;
  font-weight : 500;
  image-rendering: crisp-edges; /* Disable anti-aliasing (may have side effects) */
  shape-rendering: crispEdges; /* Disable anti-aliasing (may have side effects) */
}

th, td{
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
  padding: 5px;
  font-weight : 500;
  justify-content: center;
  align-items: center;
}

td{
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
  padding: 5px;
  font-weight : 500;
  justify-content: center;
  align-items: center;
}
/* th{
  font-weight : 400;
} */

td{
  text-align: end;
}

.table-left-text{
  text-align: start;
  width : 450px;
}

.text-center{
  text-align: center;
}

.table-no-outline{
  border : none;
  width: 250px;
}

.table-no-outline td {
  border: none;
}

.table-td-width{
  width: 150px;
  border: 1px solid red;
}

.table-no-outline tr td:first-child{
  text-align: start;
}

.text-container-two{
  text-align: end;
}

.space-container{
  height: 100px;
}

.text-container-3{
  text-align: center;
}

.container_1 {
  position: relative;
}

.container_1::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px; /* Adjust the height of the line */
  background-color: black; /* Adjust the color of the line */
}

/* YourComponent.css */

/* Styles for mobile view */
.mobile-view {
justify-content: center;
align-items: center;
text-align: center;
}

/* Styles for desktop view */
.desktop-view {
  /* Add your styles for desktop view */
}

/* Media query for smaller screens (adjust the max-width as needed) */
@media (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide (tablet and smaller) */
  .mobile-view {
    display: block;
  }

  .desktop-view {
    /* Adjust styles for smaller screens */
    display: none;
  }
}

@media (min-width: 768px) {
  /* Adjust styles for screens up to 768px wide (tablet and smaller) */
  .mobile-view {
    display: none;
  }

  .desktop-view {
    /* Adjust styles for smaller screens */
    display: block;
  }
}



