
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(100px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(200px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(300px);
}

/* Add this CSS in your main CSS file or component CSS */
.welcome-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.save-button {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */

  /* Add styles for when the button is being clicked */
  &:active {
    background-color: rgb(117, 255, 117);
  }
}

.company-info-heading {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 24px;
  margin-bottom: 16px;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
}

.company-logo,
.default-logo {
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 8px;
}

.Login_scr{
  border-radius: 5px;
  display:flex;
  align-items: center;
  text-align: center;
  width: 50vw;
  height: 40vh;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
}
.container_L1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.container_L{
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 50vw;
}
@media (max-width: 600px) {
  .container_L {
    width: 100%;
  }
}

.L_lable{
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}
.L_input{
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.border_login{
  border: 1px solid grey;
  width: 200px;
  margin:20px ;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
}

.mdhidden{
display: flex;
}
@media (max-width: 780px) {
  .mdhidden {
    display:none;
  }
}